import { useContext, useEffect, useState } from "react";
import {
    ComingNext,
    Exercise,
    ExerciseResult,
    PlaylistManager,
} from "@evidenceb/gameplay-interfaces";
import { dataStore } from "../contexts/DataContext";
import {
    getActivityById,
    getModuleById,
    getObjectiveById,
    getSublevelPool,
} from "../utils/dataRetrieval";
import { PlayerProgressionError } from "../errors";
import { PlaylistExecutionStage } from "@evidenceb/gameplay-interfaces";

// DEV STUFF
const student = "Toto";
const useAI = () => {
    const [activityId, setActivityId] = useState<string | undefined>(undefined);

    return {
        getStudentActivity: (
            studentId: string,
            objectiveId: string,
            availableActivityIds: string[]
        ) => {
            if (typeof activityId !== "undefined") return activityId;

            const newActivityId =
                availableActivityIds[
                    Math.floor(Math.random() * availableActivityIds.length)
                ];
            console.log(
                `DEV: AI determines that ${studentId} needs activity ${newActivityId} for objective ${objectiveId}`
            );
            setActivityId(newActivityId);
            return newActivityId;
        },
        record: (studentId: string, result: ExerciseResult<any>) => {
            console.log(
                `DEV: AI records that ${studentId} has the following result:`,
                result
            );
        },
    };
};
//

/**
 * This hook is meant to handle the progression through a playlist by a
 * student. A student:
 * - should follow the progression of exercises as given
 * - should have their playlist handled with AI
 * @param moduleId
 * @param objectiveId
 */
const useStudentPlaylistManager = (
    moduleId: string,
    objectiveId: string
): PlaylistManager => {
    const { data } = useContext(dataStore);
    const module = getModuleById(moduleId, data);
    const objective = getObjectiveById(objectiveId, data);

    const ai = useAI();
    const activityId = ai.getStudentActivity(
        student,
        objectiveId,
        objective.activityIds
    );
    const activity = getActivityById(activityId, data);
    const exerciseList = getSublevelPool<Exercise<any, any>>(activity.exerciseIds, data.exercises);

    const [currentExecutionStage, setCurrentExecutionStage] = useState(
        PlaylistExecutionStage.PlayingCurrentExercise
    );
    const [currentExerciseIndex, setCurrentExerciseIndex] = useState<number>(0);
    const [exerciseResults, setExerciseResults] = useState<
        ExerciseResult<any>[]
    >([]);
    const [currentTry, setCurrentTry] = useState<number>(1);
    const [currentExerciseResult, setCurrentExerciseResult] = useState<
        ExerciseResult<any> | undefined
    >(undefined);
    const [comingNext, setComingNext] = useState<ComingNext | undefined>(
        undefined
    );

    useEffect(() => {
        setComingNext(undefined);
        setCurrentExerciseResult(undefined);
    }, [currentExerciseIndex, currentTry]);

    return {
        playlist: {
            module,
            objective,
            activity,
            exercises: exerciseList,
            currentExecutionStage: currentExecutionStage,
            currentExercise: exerciseList[currentExerciseIndex],
            currentTry,
            currentExerciseResult,
            comingNext: comingNext,
            exerciseResults: exerciseResults,
        },

        recordCurrentExerciseResult: (partialExerciseResult) => {
            const exercise = exerciseList[currentExerciseIndex!];
            const exerciseResult: ExerciseResult<any> = {
                ...partialExerciseResult,
                activityId: activity.id,
                exerciseId: exercise.id,
                try: currentTry,
                feedback:
                    exercise.feedback[currentTry - 1]?.[
                        partialExerciseResult.correct ? "correct" : "incorrect"
                    ],
            };
            ai.record(student, exerciseResult);
            setCurrentExerciseResult(exerciseResult);
            setExerciseResults([...exerciseResults, exerciseResult]);
            setCurrentExecutionStage(
                PlaylistExecutionStage.ShowingCurrentExerciseResultFeedback
            );
            setComingNext(
                getWhatsComingNext(
                    currentExerciseIndex!,
                    exerciseList,
                    currentTry,
                    exerciseResult
                )
            );
        },

        goToNextExercise: () => {
            if (!comingNext)
                throw new PlayerProgressionError(
                    "goToNextExercise called before validating exercise"
                );

            if (comingNext === "retry") {
                setCurrentTry(currentTry + 1);
            } else {
                setCurrentTry(1);
                setCurrentExerciseIndex(currentExerciseIndex! + 1);
            }
            setCurrentExecutionStage(
                PlaylistExecutionStage.PlayingCurrentExercise
            );
        },

        goToExercise: () => {
            throw new PlayerProgressionError(
                "Students cannot navigate to a specific exercise"
            );
        },
    };
};

const getWhatsComingNext = (
    currentExerciseIndex: number,
    exerciseList: Exercise<any, any>[],
    currentTry: number,
    exerciseResult: ExerciseResult<any>
): PlaylistManager["playlist"]["comingNext"] => {
    if (!exerciseResult) return undefined;
    if (
        !exerciseResult.correct &&
        currentTry < exerciseList[currentExerciseIndex].numberOfTries!
    )
        return "retry";
    if (currentExerciseIndex === exerciseList.length - 1)
        return "endOfPlaylist";
    return "nextExercise";
};

export default useStudentPlaylistManager;
