export class ModuleNotFoundError extends Error {}
export class ObjectiveNotFoundError extends Error {}
export class ActivityNotFoundError extends Error {}
export class ExerciseNotFoundError extends Error {}
export class ItemNotFoundError extends Error {}

export class ModuleNotVisibleError extends Error {}
export class ObjectiveNotVisibleError extends Error {}
export class ActivityNotVisibleError extends Error {}
export class ExerciseNotVisibleError extends Error {}

export class PlayerProgressionError extends Error {}
