import React, { FunctionComponent } from 'react'
import { Provider } from '@nteract/mathjax'

const MathJaxProvider: FunctionComponent<{}> = (props) => {
    return (
        <Provider
            options={{
                extensions: ["tex2jax.js"],
                jax: ["input/TeX", "output/SVG"],
                tex2jax: {
                    inlineMath: [ ['$','$'], ["\\(","\\)"] ],
                    displayMath: [ ['$$','$$'], ["\\[","\\]"] ],
                    processEscapes: true
                },
                messageStyle: "none"
            }}
            src="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.9/MathJax.js"
        >
            {props.children}
        </Provider>
    )
}

export { MathJaxProvider }