import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

import CardEntry from "../../components/CardEntry/CardEntry";
import { homeStore } from "../../contexts/HomeContext";
import parse from "html-react-parser";
import { Helmet } from "react-helmet";

import { Title } from "../../interfaces/Config";

import "./Home.scss";

function Home({ title }: Title) {
    const { home } = useContext(homeStore);
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <Helmet>
                {" "}
                <title>{title}</title>{" "}
            </Helmet>
            <div className="home-content">
                <div className="top-content">
                    <div className="content">
                        <div className="block">
                            <h1>{home.main?.title}</h1>
                            <p>{parse(home.main!.text)}</p>
                        </div>
                        <div className="block">
                            <img src={home.main?.img} />
                        </div>
                    </div>
                </div>
                <div className="entries">
                    {home.entries.map((entry, i) =>
                        entry.display ? (
                            <CardEntry
                                key={`card-${i}`}
                                title={entry.title}
                                img={entry.img}
                                desc={entry.desc}
                                buttonLabel={entry.buttonLabel}
                                url={entry.url}
                            />
                        ) : null
                    )}
                </div>
                <div className="secondary-content">
                    {home.secondaries.map((content, i) => (
                        <div className="container" key={`container-${i}`}>
                            <div className="block">
                                <div className="img">
                                    <img src={content.img} />
                                </div>
                                <div className="content">
                                    <h2>{content.title}</h2>
                                    {parse(content.content)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default Home;
