import React from "react";
import { ProgressBarProps } from "../../interfaces/Data";
import { getExerciseLastResult, hasResult } from "../../utils/exerciseUtils";

import "./StudentProgressBar.scss";

const StudentProgressBar: React.FC<ProgressBarProps> = ({
    exercises,
    currentExercise,
    exerciseResults,
}) => {
    return (
        <ul className="student-progress-bar">
            {exercises.map((exercise, index) => {
                if (hasResult(exercise.id, exerciseResults))
                    return (
                        <li
                            key={"solved" + index}
                            className={
                                getExerciseLastResult(
                                    exerciseResults,
                                    exercise.id
                                )!.correct === true
                                    ? "progress-bar__item--correct"
                                    : "progress-bar__item--incorrect"
                            }
                        >
                            {getExerciseLastResult(
                                exerciseResults,
                                exercise.id
                            )!.correct === true ? (
                                <span className="material-icons">check</span>
                            ) : (
                                <span className="material-icons">clear</span>
                            )}
                        </li>
                    );

                if (exercise.id === currentExercise?.id)
                    return (
                        <li
                            key="solving"
                            className="progress-bar__item--solving"
                        ></li>
                    );

                return (
                    <li
                        key={"unsolved" + index}
                        className="progress-bar__item--unsolved"
                    ></li>
                );
            })}
        </ul>
    );
};

export default StudentProgressBar;
