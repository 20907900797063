import React, { useRef } from 'react'
import { Node } from '@nteract/mathjax'
import _parseHTML from 'html-react-parser'

const LATEX = /\\\((.*?)\\\)|\\\[(.*?)\\\]|\$\$?(.*?)\$\$?/
const LATEX_WITH_DELIMITERS = /(\\\(.*?\\\))|(\\\[.*?\\\])|(\$\$?.*?\$\$?)/
const LATEX_BLOCK = /\\\[(.*?)\\\]|\$\$(.*?)\$\$/

/*
    /!\ FIRST OF ALL /!\
    
    If you modify this file, be sure to modify also the
    same file in https://github.com/EvidenceB/gameplays(/src/utils/parse-text.tsx)

    ///////////\\\\\\\\\
*/

// parse() take a string and replace every html tag and LaTex with real html tag and svg LaTex
// @params
// @src is the string to parse
// @options is optional, by default every parameter are true, but you can disable some of them by setting them to false

// Examples:
// parse("<em>Hello</em>") works
// parse("$1\\over2$") works (inline LaTex)
// parse("$$1\\over2$$") works (block LaTex)
// parse("<em>Hello</em> $1\\over2$") works
// parse("<em>Hello $1\\over2$</em>") doesn't work, and these syntax is useless, LaTex cannot be modified with html tag

const parse = (
    src: string,
    options?: {mathjax: boolean, html: boolean}
) => {
    let res = options && !options.html ? src : ToHTML(src)

    if (!options || options.mathjax) {
        if (typeof(res) === "string" && res.match(LATEX)) {
            res = ToMathjax(res)
        } else if (Array.isArray(res)) {
            let tmp = [] as Array<JSX.Element | string>
            for (let r of res) {
                if (typeof(r) === "string") {
                    tmp = tmp.concat(ToMathjax(r))
                } else {
                    tmp.push(r)
                }
            }
            res = tmp
        }
    }
    return res
}

const ToHTML = (s: string) => _parseHTML(s) as JSX.Element | string | Array<JSX.Element | string>

const ToMathjax = (s: string): Array<string | JSX.Element> => {
    return s.split(LATEX_WITH_DELIMITERS).map((e, i) => {
        if (LATEX.test(e)) {
            const latex = e.match(LATEX)?.reduce((acc, cur, idx) => idx > 0 && cur ? cur : acc)
            if (latex && LATEX_BLOCK.test(e))
                return <MathJaXNode key={i} latex={latex}/>
            else if (latex)
                return <MathJaXNode key={i} inline={true} latex={latex}/>
        }
        return e
    })
}

interface MathjaxNodeProps {
    inline?: boolean,
    latex: string,
}
const MathJaXNode: React.FC<MathjaxNodeProps> = (props) => {
    const ref = useRef<HTMLSpanElement>(null)
    
    const onNodeRender = () => {
        if (ref.current && ref.current.firstChild) {
            const child  = ref.current.firstChild as HTMLSpanElement
            child.style.fontSize = "inherit"
        }
    }

    return (
        <span className={`mathjax${props.inline ? '-inline' : ''}-container`} ref={ref}>
            <Node onRender={onNodeRender} inline={props.inline}>{props.latex}</Node>
        </span>
    )
}

export { parse }