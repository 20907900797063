import React from "react";
import { useParams } from "react-router-dom";
import { getExerciseLastResult } from "../../utils/exerciseUtils";
import useStudentPlaylistManager from "../../hooks/useStudentPlaylistManager";
import PlayerHeader from "../../components/PlayerHeader/PlayerHeader";
import StudentProgressBar from "../../components/StudentProgressBar/StudentProgressBar";
import WizardShell from "../../components/WizardShell/WizardShell";
import ChatbotShell from "../../components/ChatbotShell/ChatbotShell";
import ExerciseIdentifier from "../../components/ExerciseIdentifier/ExerciseIdentifier";

import "./PlaylistPlayer.scss";
import PlaylistSummary from "../../components/PlaylistSummary/PlaylistSummary";
import { ActivityShell } from "@evidenceb/gameplay-interfaces";

const StudentPlaylistPlayer: React.FC = () => {
    console.log("reinited");

    const { moduleId, objectiveId } = useParams<{
        moduleId: string;
        objectiveId: string;
    }>();
    const {
        playlist,
        goToNextExercise,
        recordCurrentExerciseResult,
    } = useStudentPlaylistManager(moduleId, objectiveId);
    const Shell =
        playlist.activity.shell === ActivityShell.Chatbot
            ? ChatbotShell
            : WizardShell;

    return (
        <>
            <PlayerHeader>
                <StudentProgressBar
                    exercises={playlist.exercises}
                    currentExercise={playlist.currentExercise}
                    exerciseResults={playlist.exerciseResults}
                />
            </PlayerHeader>

            <main>
                {playlist.currentExercise ? (
                    <Shell
                        currentExercise={playlist.currentExercise}
                        currentTry={
                            getExerciseLastResult(
                                playlist.exerciseResults,
                                playlist.currentExercise.id
                            )?.try || 1
                        }
                        comingNext={playlist.comingNext}
                        currentExerciseResult={playlist.currentExerciseResult}
                        onGoToNextExercise={goToNextExercise}
                        onExerciseResult={recordCurrentExerciseResult}
                        playlist={playlist}
                    />
                ) : (
                    <PlaylistSummary
                        exerciseResults={playlist.exerciseResults}
                    />
                )}
            </main>

            {playlist.currentExercise ? (
                <ExerciseIdentifier
                    moduleId={moduleId}
                    objectiveId={objectiveId}
                    activityId={playlist.activity.id}
                    exerciseId={playlist.currentExercise.id}
                />
            ) : null}
        </>
    );
};

export default StudentPlaylistPlayer;
