import React, { useContext, useState } from "react";
import ChatbotDrawer from "./Drawer/ChatbotDrawer";
import ExerciseResult from "./ExerciseResult/ExerciseResult";
import PlaylistExecutionStage from "@evidenceb/gameplay-interfaces/build/entities/PlaylistExecutionStage";
import Welcome from "./Welcome";
import { CurrentExercise } from "./CurrentExercise";
import { Shell } from "@evidenceb/gameplay-interfaces";
import { configStore } from "../../contexts/ConfigContext";
import { dataStore } from "../../contexts/DataContext";
import { getExerciseById } from "../../utils/dataRetrieval";

import "./ChatbotShell.scss";

const ChatbotShell: React.FC<Shell<any, any>> = ({
    playlist,
    onExerciseResult,
    onGoToNextExercise,
}) => {
    const { data } = useContext(dataStore);
    const { config } = useContext(configStore);
    const [started, setStarted] = useState(false);

    return (
        <div className="chatbot-layout">
            <ChatbotDrawer
                activity={playlist!.activity}
                objective={playlist!.objective}
            />

            <div className="chatbot-core">
                <Welcome
                    onStart={() => setStarted(true)}
                    started={started}
                    welcomeMessages={config.i18n.chatbot.welcomeMessages}
                    startButtonText={config.i18n.chatbot.start}
                />
                <>
                    {playlist!.exerciseResults.map((exerciseResult, index) => {
                        const isLastExerciseResult =
                            index === playlist!.exerciseResults.length - 1;
                        return (
                            <ExerciseResult
                                exercise={getExerciseById(
                                    exerciseResult.exerciseId,
                                    data
                                )}
                                exerciseResult={exerciseResult}
                                key={index}
                                onGoToNextExercise={
                                    isLastExerciseResult &&
                                    playlist?.currentExecutionStage ===
                                        PlaylistExecutionStage.ShowingCurrentExerciseResultFeedback
                                        ? onGoToNextExercise
                                        : undefined
                                }
                                comingNext={playlist?.comingNext}
                            />
                        );
                    })}
                </>
                {started &&
                playlist?.currentExercise &&
                playlist?.currentExecutionStage ===
                    PlaylistExecutionStage.PlayingCurrentExercise ? (
                    <>
                        <CurrentExercise
                            currentExercise={playlist.currentExercise}
                            key={`current-${playlist.currentExercise.id}-${playlist.currentTry}`}
                            onExerciseResult={onExerciseResult}
                        />
                        <div className="current-exercise__background-shader"></div>
                    </>
                ) : null}
            </div>
        </div>
    );
};
export default ChatbotShell;
