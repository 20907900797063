import { VisibilityStatus } from "@evidenceb/gameplay-interfaces";
import { useContext, useEffect, useState } from "react";
import { configStore } from "../contexts/ConfigContext";
import { contentPagesStore } from "../contexts/ContentPagesContext";
import { dataStore } from "../contexts/DataContext";
import { homeStore } from "../contexts/HomeContext";
import { Config } from "../interfaces/Config";
import { ContentPage } from "../interfaces/ContentPage";
import { RawData } from "../interfaces/Data";
import { Home } from "../interfaces/Home";
import { ErrorLog, Status } from "../interfaces/Status";
import { getExercisesWithAvailableGameplays } from "../utils/fetch-gameplays";
import { applyTheme, Theme } from "../utils/theme-handler";
import windowLogger from "../utils/window-logger";
import chatbotTheme from "./chatbotTheme";
import config from "../config";

export interface GlobalConfig {
    config: Config;
    home: Home;
    contentPages: ContentPage[];
    theme: Theme;
}

export default function useSetContexts() {
    const { setData } = useContext(dataStore);
    const { setHome } = useContext(homeStore);
    const { setContentPages } = useContext(contentPagesStore);
    const { setConfig } = useContext(configStore);

    const [status, setStatus] = useState<Status>({
        type: "pending",
        errors: [],
    });

    useEffect(() => {
        const errorLog: ErrorLog[] = [];
        function getJson<T>(url: string): Promise<T> {
            return fetch(url, { cache: "no-cache" })
                .then((response) => {
                    return response.json() as Promise<T>;
                })
                .then((data) => {
                    return data;
                })
                .catch((err) => {
                    errorLog.push({ info: err, type: "JSON" });
                    return err;
                });
        }

        async function getAllJson() {

            /* --------------- TEMP ------------- */
            // these informations will be in the token from the ENT later on
            // find a better multilangue/versions implementation

            let client = "";
            let version = "";
            
            let path = ""
            
            let hostname = window.location.hostname.split(".")[1];
            
            let lang = window.location.pathname.split('/')[1] ? window.location.pathname.split('/')[1] : "fr";
            if ( localStorage.getItem('lang') === null || localStorage.getItem('lang') !== lang) {
                localStorage.setItem('lang', lang);
            }
            
            hostname = hostname === "adlang" ? "adaptivlangue" : hostname;
            switch (hostname) {
                case "adaptivlangue":
                    client = "adlang";
                    version = "idf-diag-visible";
                    path = "adlang-bucket/versions/default/idf-diag-visible";
                    break;
                case "adaptivfraction":
                    client = "adfrac";
                    version = "adfrac-refacto";
                    path = `adfrac-bucket/versions/${lang}/adfrac-refacto`;
                    break;
                case "atticus":
                    client = "atticus";
                    version = "default";
                    path= "atticus-bucket/versions/default/main"
                    break;
            }
            
            if ( localStorage.getItem('client') === null || localStorage.getItem('client') !== client) {
                localStorage.setItem('client', client);
            } 
            if ( localStorage.getItem('version') === null || localStorage.getItem('version') !== lang) {
                localStorage.setItem('version', version);
            }
            /* ---------------------------- */

            //Sequence of async fetch
            const global = await getJson<GlobalConfig>(
                config.configJsonUrl ??
                `https://cellar-c2.services.clever-cloud.com/${path}/config/config.json`
            );
            // TEMP: remove non-visible objects until they're handled properly
            // by the app
            const data = removeNonVisible(
                await getJson<RawData>(
                    config.dataJsonUrl ??
                    `https://cellar-c2.services.clever-cloud.com/${path}/questions/data.json`
                )
            );
            // Uncomment (and comment the statement above) to load the local data
            // const data = (await import("./data")).default;
            registerDebugUtils(data);

            const exercises = await getExercisesWithAvailableGameplays(
                data.exercises,
                (exercise, reason) => {
                    windowLogger.error(
                        `Exercise could not be imported: ${reason}`,
                        exercise.id
                    );
                }
            );

            // if no error is catch, set content and send fetched status, if error(s) send error status and log to display appropriate error page
            if (errorLog.length === 0) {
                applyTheme({ ...global.theme, chatbot: chatbotTheme }); //TODO: fallback theme, theme errors handler
                setConfig(global.config);
                setHome(global.home);
                setContentPages(global.contentPages);

                setData({
                    modules: data.modules,
                    objectives: data.objectives,
                    activities: data.activities,
                    exercises,
                });
                setStatus({ type: "fetched", errors: [] });
            } else {
                setStatus({ type: "error", errors: errorLog });
            }
        }

        getAllJson();
    }, [setConfig, setContentPages, setData, setHome]);

    return { status };
}

function removeNonVisible(data: RawData): RawData {
    const exercises = data.exercises.filter(isVisible);
    const existsInExercises = existsIn(exercises);
    const activities = data.activities.filter(isVisible).map((activity) => ({
        ...activity,
        exerciseIds: activity.exerciseIds.filter(existsInExercises),
    }));
    const existsInActivities = existsIn(activities);
    const objectives = data.objectives.filter(isVisible).map((objective) => ({
        ...objective,
        activityIds: objective.activityIds.filter(existsInActivities),
    }));
    const existsInObjectives = existsIn(objectives);
    const modules = data.modules.filter(isVisible).map((module) => ({
        ...module,
        objectiveIds: module.objectiveIds.filter(existsInObjectives),
    }));
    return { modules, objectives, activities, exercises };
}

function isVisible(object: { visibilityStatus: VisibilityStatus }): boolean {
    return object.visibilityStatus === VisibilityStatus.Visible;
}

function existsIn(elements: { id: string }[]) {
    const ids = new Set(elements.map((element) => element.id));
    return (id: string): boolean => ids.has(id);
}

function registerDebugUtils(data: RawData) {
    (window as any).DEBUG_UTILS = {
        getModuleById: (id: string) =>
            data.modules.find((module) => module.id === id),
        getObjectiveById: (id: string) =>
            data.objectives.find((objective) => objective.id === id),
        getActivityById: (id: string) =>
            data.activities.find((activity) => activity.id === id),
        getExerciseById: (id: string) =>
            data.exercises.find((exercise) => exercise.id === id),
    };
}
