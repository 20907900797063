import React, { useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { Page } from "../../interfaces/Config";
import "./Header.scss";

interface HeaderProps {
    logo: string;
    pages: Page[];
}

function Header({ logo, pages }: HeaderProps) {
    const menuContainer = useRef<HTMLDivElement>(null);
    const lang = localStorage.getItem('lang');
    const toggleMobileMenuHandler = () => {
        menuContainer.current?.classList.toggle("show");
    };
    const logoutHandler = () => {
        toggleMobileMenuHandler();
    };

    return (
        <header className="header-top">
            <div className="container">
                <div className="logo">
                    <Link to={`/${lang}`}>
                        <img src={logo} />
                    </Link>
                </div>
                <button
                    onClick={toggleMobileMenuHandler}
                    className="mobileMenuBtn"
                >
                    <span className="material-icons">dehaze</span>
                </button>
                <div ref={menuContainer} className="menu-container">
                    <nav>
                        {pages.map((page, i) =>
                            page.type !== "LOGOUT" && page.inTopNav ? (
                                <li
                                    onClick={toggleMobileMenuHandler}
                                    key={`navItem-${i}`}
                                >
                                    <NavLink exact to={`/${lang}/${page.url}`}>
                                        {page.label}
                                    </NavLink>
                                </li>
                            ) : page.type === "LOGOUT" && page.inTopNav ? (
                                <button
                                    key={`navItem-${i}`}
                                    onClick={logoutHandler}
                                >
                                    <Link to={`${lang}/${page.url}`}>
                                        <span className="material-icons">
                                            power_settings_new
                                        </span>
                                    </Link>
                                </button>
                            ) : null
                        )}
                    </nav>
                    <button
                        onClick={toggleMobileMenuHandler}
                        className="closeMobileMenuBtn"
                    >
                        <span className="material-icons">clear</span>
                    </button>
                </div>
            </div>
        </header>
    );
}

export default Header;
